import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

export const ProfilPejabatTemplate = ({
  helmet,
  featuredimage,
  title,
  jabatan,
  es2,
  es3,
  es4,
  nip,
  nik,
  tanggalLahir,
  categories = [],
}) => {

  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <h1 className="title is-size-2 has-text-weight-bold is-bold-light" style={{ textAlign: 'center' }}>
             {title}
        </h1>
        <div style={{ maxWidth: '700px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
            <div>
              {featuredimage &&
                <PreviewCompatibleImage
                  imageInfo={{
                    image: featuredimage,
                    alt: title,
                  }}
                />
              }
            </div>
        </div>
        <div style={{ maxWidth: '500px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
          <table>
            <tbody>
              <tr>
                <td>Nama</td>
                <td>{title}</td>
              </tr>
              {nip &&
              <tr>
                <td>NIP</td>
                <td>{nip}</td>
              </tr>
              }
              {nik &&
              <tr>
                <td>NIK</td>
                <td>{nik}</td>
              </tr>
              }
              {tanggalLahir &&
              <tr> 
                <td>Tgl.Lahir</td>
                <td>{tanggalLahir}</td>
              </tr>
              }
              <tr>
                <td>Jabatan</td>
                <td>{jabatan}</td>
              </tr>
            {es2 &&
              <tr>
                <td>Eselon 2</td>
                <td>{es2}</td>
              </tr>
            }
            {es3 &&
              <tr>
                <td>Eselon 3</td>
                <td>{es3}</td>
              </tr>
            }
            {es4 &&
              <tr>
                <td>Eselon 4</td>
                <td>{es4}</td>
              </tr>
            }
            </tbody>
          </table>
        </div>
          <br /> <br />
          <Link to="/boss"><button style={{ display: 'block', marginRight: 'auto', marginLeft: 'auto' }}>Kembali</button></Link>
      </div>
    </section>
  )
}

ProfilPejabatTemplate.propTypes = {
  title: PropTypes.string,
  featuredimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  helmet: PropTypes.object,
  featuredpost: PropTypes.string,
  jabatan: PropTypes.string,
  es2: PropTypes.string,
  es3: PropTypes.string,
  es4: PropTypes.string,
  nip: PropTypes.string,
  nik: PropTypes.string,
}

const ProfilPejabat = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <ProfilPejabatTemplate
        helmet={
          <Helmet titleTemplate="%s | Profil">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.jabatan}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        featuredimage={post.frontmatter.featuredimage}
        jabatan={post.frontmatter.jabatan}
        es2={post.frontmatter.es2}
        es3={post.frontmatter.es3}
        es4={post.frontmatter.es4}
        nip={post.frontmatter.nip}
        nik={post.frontmatter.nik}
        tanggalLahir={post.frontmatter.tanggalLahir}
        category={post.frontmatter.categories.category}
      />
    </Layout>
  )
}


ProfilPejabat.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ProfilPejabat

export const profilPageQuery = graphql`
  query ProfilPejabatByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        featuredpost
        featuredimage {
            childImageSharp {
              fluid(maxWidth: 250, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        jabatan
        es2
        es3
        es4
        nip
        nik
        categories {
              category
            }
        tanggalLahir(formatString: "DD-MM-YYYY")
      }
    }
  }
`